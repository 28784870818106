<template>
	<div class="d-flex flex-column justify-center" style="height: 100%;">
		<div class="d-flex justify-center">
			<v-card class="card pb-5">
            <v-card-title class="justify-center">登录</v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid" lazy-validation>
		
						<v-text-field v-model="email" label="邮箱" required :rules="[rules.email]"></v-text-field>

						<v-text-field type="password" v-model="password" label="密码" required :rules="[rules.length(6)]"></v-text-field>
						
						<div class="d-flex justify-space-between">
							<v-btn text class="button" to="/register">注册</v-btn>
							<v-btn text class="button" to="/update-password">找回密码</v-btn>
						</div>
						
						<v-btn :disabled="!valid" :loading="isConfirmBtnLoading" @click="confirm" large block class="mt-7 text-none" color="primary">确认</v-btn>
						
					</v-form>
				</v-card-text>
			</v-card>
			
		</div>
	</div>
</template>
<script>
import http from '../api/http';

export default {
    data: () => ({
        valid: true,
        email: '',
        password: '',
        isConfirmBtnLoading: false,
        rules: {
            email: v => !!(v || '').match(/@/) || '邮箱格式不正确',
            length: len => v => (v || '').length >= len || `密码长度需大于 ${len} 位`,
        },

    }),
    methods: {

        async confirm() {
            if (!this.$refs.form.validate()) return
            this.isConfirmBtnLoading = true

            try {
                const data = {
                    email: this.email,
                    password: this.password
                };

                const response = await http.put('/user/login', data)
                this.isConfirmBtnLoading = false

                localStorage.setItem('email', this.email)
                localStorage.setItem('token', response.data.data.token)
                this.$router.push( { path: '/overview' } )

            } catch (error) {
                this.isConfirmBtnLoading = false
                this.$root.$emit('show-snackbar', {
                    text: error.response.data.msg
                })
            }
        }
    }

}
</script>

<style scoped>
	.card{
		max-width: 450px;
		width: 90%;
	}
	.button{
        min-width: 0 !important;
        padding: 0 !important;
	}
</style>
